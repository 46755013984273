<template>
  <div class="flex md:h-screen bg-endark relative">
    <div class="m-auto p-6 text-center sm:text-left">
      <h1
        class="
          mt-4 md:mt-0
          text-6xl
          md:text-7xl
          lg:text-8xl
          font-poppins font-medium
          text-enorange
          tracking-tight
        "
      >
        Enchanter
      </h1>
      <p
        class="
          mt-1
          text-xl
          md:text-2xl
          font-poppins font-medium
          text-engreen
          tracking-tight
        "
      >
        ‘CHan(t)ər &nbsp; / &nbsp; Name
      </p>

      <p
        class="
          mt-2
          text-lg
          leading-7
          sm:text-xl sm:leading-8
          md:text-2xl md:leading-9
          font-poppins font-light
          text-white
          tracking-tight
        "
      >
        <em
          >"Enchanter is a class of champions in League of Legends that focuses
          on amplifying their teammates’ effectiveness and abilities."</em
        >
      </p>

      <div class="mt-6 lg:hidden">
        <img
          class="w-full max-h-64"
          src="@/assets/img/main-illustration.svg"
          alt=""
        />
      </div>

      <h2
        class="
          mt-6 lg:mt-12
          text-xl
          leading-8
          sm:text-2xl sm:leading-9
          md:text-3xl md:leading-11
          font-normal font-poppins
          text-white
          tracking-tight
        "
      >
        I’m Antoine Frankart, a Consultant specialized in Digital Product
        Management, with 13+ years of experience in the Esports industry. 
        <br class="sm:hidden" />
        I can be your Enchanter.
      </h2>

      <div class="text-center lg:text-left">
        <a
          class="
            inline-block
            m-auto
            mt-6
            py-3
            px-6
            rounded-md
            bg-engreen
            hover:bg-engreen-dark
            focus:outline-none
            text-lg
            sm:text-xl
            md:text-2xl
            font-poppins font-normal
            text-white
            tracking-tight
            shadow
            md:shadow-md
          "
          href="https://www.linkedin.com/in/emerika/"
        >
          <svg
            class="inline-block align-middle mr-2 text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
          >
            <path
              d="M19 0H5a5 5 0 00-5 5v14a5 5 0 005 5h14a5 5 0 005-5V5a5 5 0 00-5-5zM8 19H5V8h3v11zM6.5 6.732c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zM20 19h-3v-5.604c0-3.368-4-3.113-4 0V19h-3V8h3v1.765c1.396-2.586 7-2.777 7 2.476V19z"
            />
          </svg>
          Let's talk
        </a>
      </div>

      <div
        class="
          hidden
          md:block
          absolute
          right-14
          bottom-16
          uppercase
          text-xs
          font-normal
          tracking-wider
          text-white text-center
        "
      >
        <div class="icon-scroll"></div>
        scroll down
      </div>
    </div>

    <div class="m-auto px-2">
      <img
        class="hidden lg:block"
        src="@/assets/img/main-illustration.svg"
        alt=""
      />
    </div>
  </div>

  <Projects />

  <Skills />

  <Contact />

  <Footer />
</template>

<script>
import Projects from '@/components/Projects.vue';
import Skills from '@/components/Skills.vue';
import Contact from '@/components/Contact.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'HelloWorld',
  components: {
    Projects,
    Skills,
    Contact,
    Footer,
  },
};
</script>

<style scoped></style>
