<template>
  <div class="px-6 pt-24 pb-10 md:pt-0">
    <div class="text-center font-poppins">
      <h2
        class="text-3xl font-medium tracking-tight sm:text-4xl md:text-5xl text-enorange"
      >
        Case Studies
      </h2>
      <p class="mt-2 text-base font-light text-white sm:text-lg md:text-xl">
        The main esports projects I worked on
      </p>
    </div>

    <div
      class="grid grid-cols-1 gap-6 mt-8 2xsm:grid-cols-2 lg:grid-cols-3 md:mt-12 md:gap-8 lg:mt-14 lg:gap-10 font-poppins"
    >
      <div
        class="overflow-hidden bg-white rounded-md shadow md:shadow-md"
        v-for="project in listProjectsReversed"
        :key="project.title"
      >
        <div class="h-auto">
          <div class="w-full aspect-w-3 aspect-h-3">
            <img
              class="object-cover object-center w-full h-full lg:w-full lg:h-full"
              :src="project.imageUrl"
              alt="{{ project.title }} illustration"
            />
          </div>

          <div class="flex flex-col p-5">
            <p class="text-lg font-medium text-enorange">
              {{ project.date }}
            </p>

            <h2 class="text-2xl font-semibold text-endark">
              {{ project.title }}
            </h2>

            <p class="text-lg font-normal text-gray-400">
              {{ project.role }}
            </p>

            <p class="mt-4 text-base font-light text-gray-800">
              {{ project.description }}
            </p>

            <p
              v-if="project.client"
              class="mt-4 text-base font-light text-gray-800"
            >
              {{ project.client }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const listProjects = [
  {
    title: 'eGame',
    description: 'Website builder & CMS for esports teams and clans. Grew organically up to 80,000 hosted websites.',
    date: '2007 - 2010',
    client: null,
    role: 'Solo Entrepreneur',
    imageUrl: require('@/assets/img/projects/egame.jpg'),
  },
  {
    title: 'Gamersband',
    description:
      'Geo-social network for gamers with clubs, tournaments and gamification.',
    date: '2010 - 2012',
    client: null,
    role: 'Co-Founder & CTO',
    imageUrl: require('@/assets/img/projects/gamersband.jpg'),
  },
  {
    title: 'PES League France',
    description:
      'The official platform for the French PES community, with clubs, tournaments, and player profiles.',
    date: '2012 - 2013',
    client: 'Client: Konami',
    role: 'Project Manager & Dev',
    imageUrl: require('@/assets/img/projects/pesleague.jpg'),
  },
  {
    title: 'COD League France',
    description:
      ' The official website of the French Call of Duty League, with team & player profiles, and tournaments.',
    date: '2013 - 2014',
    client: 'Client: Activision',
    role: 'Project Manager & Dev',
    imageUrl: require('@/assets/img/projects/codfrance.jpg'),
  },
  {
    title: 'ESWC',
    description:
      'The Esports World Convention: massive offline events. Created streaming overlays and tools to follow the competitions.',
    date: '2011 - 2016',
    client: null,
    role: 'Head of Digital',
    imageUrl: require('@/assets/img/projects/eswc.jpg'),
  },
  {
    title: 'Just Dance World Cup',
    description:
      'The official website of the competition, displaying rankings based on online qualifier results, retrieved by using a Ubisoft API.',
    date: '2014 - 2016',
    client: 'Client: Ubisoft',
    role: 'Project Manager & Dev',
    imageUrl: require('@/assets/img/projects/jdwc.jpg'),
  },
  {
    title: 'League Of Legends French League',
    description:
      'The official website with results, ranking and team information, displayed automatically thanks to the Toornament technology.',
    date: '2019 – 2020',
    client: 'Client: Riot Games',
    role: 'Product Owner',
    imageUrl: require('@/assets/img/projects/lfl.jpg'),
  },
  {
    title: 'Rainbow 6 French League',
    description:
      'The official website with results, ranking and team information, displayed automatically thanks to the Toornament technology.',
    date: '2019 – 2020',
    client: 'Client: Ubisoft',
    role: 'Product Owner',
    imageUrl: require('@/assets/img/projects/r6fl.jpg'),
  },
  {
    title: 'Gears Esports',
    description:
      'The official Gears Esports platform relying on the Toornament technology. Microsoft SSO implemented for the user login. Custom functionalities created: roster lock system, season rankings, ladder, etc.',
    date: '2018 – 2020',
    client: 'Client: Microsoft',
    role: 'Product Owner',
    imageUrl: require('@/assets/img/projects/gears.jpg'),
  },
  {
    title: 'Toornament.com',
    description:
      'The leading B2B esports technology to organize competitions. Acquired by Webedia in 2016. In charge of the project, including team management, product strategy, KPI measurement and business development.',
    date: '2013 - 2020',
    client: null,
    role: 'Co-Founder & Director',
    imageUrl: require('@/assets/img/projects/toornament.jpg'),
  },
  {
    title: 'Consulting',
    description:
      'Helping game publishers and brands build digital assets for their esports projects: websites, platforms, landing pages, streaming tools, etc.',
    date: '2020 - Present',
    client: 'Clients: Ubisoft, Red Bull, Ultra.io, etc.',
    role: 'Freelancer',
    imageUrl: require('@/assets/img/projects/consulting.jpg'),
  },
  {
    title: '1980.gg',
    description:
      'The landing page builder & link-in-bio tool for esports. In few minutes, create a unique page for your team, your event, your gaming resume, a sponsor activation etc.',
    date: '2021 - Present',
    client: null,
    role: 'Founder (Bootstrapped)',
    imageUrl: require('@/assets/img/projects/1980gg.jpg'),
  },
];

export default {
  name: 'Projects',
  setup() {
    return {
      listProjects,
    };
  },
  computed: {
    listProjectsReversed() {
      return this.listProjects.slice().reverse();
    },
  },
};
</script>
